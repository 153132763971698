import image from 'assets/image';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Page from '../../components/Page';
import Banner from './banner/Banner';
import ClientReview from './clientReview/ClientReview';
import GetMore2 from './getMore/GetMore2';
import GetStarted from './getStarted/GetStarted';
import MoolahVideoDetails from './moolahVideoDetails/MoolahVideoDetails';
import './style.css';
import WhyLoveMoolahMate from './whyLoveMoolahMate/WhyLoveMoolahMate';

export default function HomePage() {
    const location = useLocation();
    const { hash } = location;

    useEffect(() => {
        if (hash === '#reviews') {
            document.querySelector(`#reviews`).scrollIntoView();
        }
        if (hash === '#pricing') {
            document.querySelector(`#pricing`).scrollIntoView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash]);

    return (
        <Page
            title={'MoolahMate: Your Personal Money Manager Toolkit Made Easy'}
            description={
                'Looking for an app to manage your finances that is easy to use? MoolahMate is here to help you with your daily and long-term financial outlook. Sign up now'
            }
        >
            <Banner />
            <GetMore2 imageUrl={image.footerGraphicAnimation} orderType={''} />
            <MoolahVideoDetails />
            <WhyLoveMoolahMate />
            <GetStarted />
            {/* <DataSecurityPrivacy/> */}
            {/* <EverythingYouNeed /> */}
            {/* <MateMoolahMate/> */}
            {/* <GetYourFinancial/> */}
            <ClientReview />
            {/* <MoolahMateCalculators/> */}
            {/* <InsightfulReporting/>
            <SliderBlog/>
            <GetMore imageUrl={image.footerGraphicAnimation} orderType={''}/> */}
        </Page>
    );
}
