import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Trans, useTranslation } from 'react-i18next';
import image from 'assets/image';
import AppDownloadIcons from 'components/app-download-icons/AppDownloadIcons';
import PageHeadingStyle from '../../../components/PageHeadingStyle';

const SUBSCRIPTION_OPTIONS = [
    {
        id: 1,
        text: 'get-more.monthly-subscription',
    },
    {
        id: 2,
        text: 'get-more.annual-subscription',
    },
];

export default function GetMore2(props) {
    const { t } = useTranslation();
    return (
        <Grid className="sectionPadding mdPaddingBottom" id="pricing">
            <Grid container spacing={2}>
                <Grid item md={6} className="mobileMargin mobileOrder" style={{ order: props.orderType }}>
                    <img src={props.imageUrl} alt="Header logo" style={{ width: 620 }} />
                </Grid>
                <Grid item md={6} className="getMoreDetails">
                    <PageHeadingStyle
                        headLineOne={t('home-page.headline-10')}
                        headLineTwo={t('home-page.subtitle-10')}
                    />
                    <Typography variant="body1" className="moreDetailsTxt">
                        <span>{t('home-page.sub-text-1')}</span>
                    </Typography>
                    <Typography variant="h6">Thereafter, you have two options:</Typography>
                    <ul className="getMorePoints">
                        {SUBSCRIPTION_OPTIONS.map((item, indx) => (
                            <li key={indx}>
                                <Typography variant="body" className="getMorePointsStyle">
                                    <img src={image.rightIcon} alt="" />{' '}
                                    <Typography variant="body">
                                        <Trans i18nKey={item.text} />
                                    </Typography>
                                </Typography>
                            </li>
                        ))}
                    </ul>
                    <LoadingButton className="loadingButtonStyle2" type="submit" variant="outlined" href="/register">
                        {t('common-translation.start-button')}
                    </LoadingButton>

                    <AppDownloadIcons />
                </Grid>
            </Grid>
        </Grid>
    );
}
